import React from 'react';

const Content = (props) => (
  <div
    dangerouslySetInnerHTML={{
      __html: props.html
    }}
  />
);

export default Content;
