import React from 'react';
import { FaBath, FaBed } from 'react-icons/fa';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const LodgingCard = (props) => (
  <div className="card lodging-card">
    <div className="lodging-card-header">
      <h5 className="subtitle is-6 has-text-centered is-uppercase">{props.title}</h5>
    </div>
    <div className="card-image">
      <Link to={props.path}>
        <figure className="image">
          <Img fluid={props.fluid} alt={props.title} />
        </figure>
      </Link>
    </div>
    <div className="card-content">
      <div className="lodging-card-items">
        <div className="lodging-card-item">
          <FaBed /> <span className="icon-desc">{props.rooms}</span>
        </div>
        <div className="lodging-card-item">
          <FaBath /> <span className="icon-desc">{props.baths} Bath</span>
        </div>
        <div className="lodging-card-item">{props.amenities}</div>
        <div className="lodging-card-item lodging-card-rate">${props.rate}/night + tax</div>
      </div>
    </div>
  </div>
);

export default LodgingCard;
