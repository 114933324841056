import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/bulma/BulmaLayout';
import Content from '../components/Content';
import PageTitle from '../components/PageTitle';
import SectionWrapper from '../components/bulma/BulmaSectionWrapper';
import LodgingCard from '../components/lodging/LodgingCard';

const IndexPage = ({ data }) => {
  const page = data.page.content.childMarkdownRemark;
  const lodge = data.lodge;
  const cabin = data.cabin;
  const house = data.house;

  return (
    <Layout>
      <section className="hero">
        <Img fluid={data.heroImage.childImageSharp.fluid} className="banner-img" />
      </section>
      <SectionWrapper>
        <PageTitle text={page.frontmatter.title} />
        <div className="columns">
          <div className="column is-three-fifths">
            <Content html={page.html} />
          </div>
          <div className="column map-container">
            <div className="location-map-fixed">
              <img src={page.frontmatter.mapUrl} alt="Google map of Basalt, CO" />
            </div>
          </div>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <div className="columns">
          <div className="column">
            <LodgingCard
              path={lodge.path}
              title={lodge.title}
              rooms={lodge.bedsText}
              baths={lodge.bath}
              amenities={lodge.amenities}
              rate={lodge.rate}
              fluid={data.unitCardImg.childImageSharp.fluid}
            />
          </div>
          <div className="column">
            <LodgingCard
              path={cabin.path}
              title={cabin.title}
              rooms={cabin.bedsText}
              baths={cabin.bath}
              amenities={cabin.amenities}
              rate={cabin.rate}
              fluid={data.cabinCardImg.childImageSharp.fluid}
            />
          </div>
          <div className="column">
            <LodgingCard
              path={house.path}
              title={house.title}
              rooms={house.bedsText}
              baths={house.bath}
              amenities={house.amenities}
              rate={house.rate}
              fluid={data.houseCardImg.childImageSharp.fluid}
            />
          </div>
        </div>
      </SectionWrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    page: indexJson {
      content {
        childMarkdownRemark {
          html
          frontmatter {
            title
            metaDescription
            mapUrl
          }
        }
      }
    }
    lodging: lodgingJson {
      specialTitle
      specialText
    }
    lodge: lodgeJson {
      path
      title
      bedsText
      bath
      amenities
      rate
    }
    cabin: cabinJson {
      path
      title
      bedsText
      bath
      amenities
      rate
    }
    house: houseJson {
      path
      title
      bedsText
      bath
      amenities
      rate
    }
    heroImage: file(relativePath: { regex: "/riverside-units-banner/" }) {
      childImageSharp {
        fluid(maxWidth: 1290) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    houseCardImg: file(relativePath: { regex: "images/house-riverside-card/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cabinCardImg: file(relativePath: { regex: "images/cabin-riverside-card/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    unitCardImg: file(relativePath: { regex: "images/riverside-units-card/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
